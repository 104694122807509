<template>
  <PageContent class="facture">
    <template #header-right>
      <ButtonClassic
        :label="$t('filtre.filtres')"
        color="primary"
        :variant="showFilterSidebar ? 'solid' : 'ghost'"
        :class="{ active: showFilterSidebar, }"
        icon="right"
        :disabled="isLoading || emptyData"
        @click="showFilterSidebar = !showFilterSidebar"
      >
        <template #right-icon>
          <UilFilter v-if="!showFilterSidebar" />
          <UilTimes v-else />
        </template>
      </ButtonClassic>
      <ButtonGroup>
        <ButtonClassic
          balise-type="button"
          variant="solid"
          icon="right"
          :disabled="!selections.length"
          @click="downloadPdf('facture')"
        >
          <template #right-icon>
            <IconPDF class="icon-medium" />
          </template>
        </ButtonClassic>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>
    <template #action-bar>
      <FiltersActionBar
        v-if="!isLoading && crossFilters?.length"
        :active-filters="crossFilters"
        :total-results="totalRows"
        @delete-tag="uncheckCrossFilter($event)"
        @reset-filters="resetCrossFilters()"
      />
    </template>

    <template #aside-content>
      <FilterSidebar
        v-if="showFilterSidebar && allFilters.length"
        :title="$t('recherche.affiner-votre-recherche')"
        :possible-filters="allFilters"
        :active-filters="activeFilters"
        @update="fetchFactures()"
      />
    </template>

    <template
      v-if="!isLoading"
      #content
    >
      <template v-if="!emptyData">
        <FacturesTable
          :rows="factures"
          :active-sort="sort"
          @sort="handleSort($event)"
        />
        <div
          v-if="totalRows > pageSize"
          class="pagination-container"
        >
          <Pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
          />
        </div>
      </template>
      <div
        v-else
        class="empty-page"
      >
        <h2 class="s4 light">
          {{ $t('facture.vous-n-avez-aucune-facture') }}
        </h2>
        <img
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_cart.svg')"
          :alt="$t('general.alt-image-illustration')"
        />
      </div>
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  PageContent,
  ButtonClassic,
  ButtonGroup,
  InfiniteLoader,
  Pagination,
  copyToClipboard,
} from "@lde/core_lde_vue";

import FiltersActionBar from "@/components/search/filters/FiltersActionBar.vue";
import FilterSidebar from "@/components/search/filters/FilterSidebar.vue";
import FacturesTable from "@/components/table/FacturesTable.vue";

import DownloadMultiPdf from "@/mixins/mixinDownloadMultiPdf";
import SearchFilters from "@/mixins/mixinSearchFilters";

import Api from "@/modules/axios";
import { handleSort } from "@/modules/utils";

import IconPDF from "@/components/icons/IconPDF.vue";

import { UilFilter, UilTimes, UilShareAlt } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Vue de la liste des factures.
 */
export default {
  name: "Facture",
  components: {
    PageContent,
    ButtonClassic,
    ButtonGroup,
    FiltersActionBar,
    FilterSidebar,
    Pagination,
    FacturesTable,
    InfiniteLoader,
    IconPDF,
    UilFilter,
    UilTimes,
    UilShareAlt,
  },
  mixins: [DownloadMultiPdf, SearchFilters],
  data() {
    return {
      showFilterSidebar: false,
      factures: [],
      isLoading: true,
      currentPage: 1,
      pageSize: 24,
      totalRows: 0,
      sort: {},
      currentUrl: window.location.href,
    };
  },
  computed: {
    ...mapGetters(["organismeActuel"]),
    selections() {
      return this.factures.filter((facture) => facture._checked);
    },
    emptyData() {
      return !this.isLoading && !this.factures?.length;
    },
    crossFilters() {
      // Filtres venant d'autres vues. Utilisés pour trouver les factures, commandes et expéditions liées.
      const res = [];

      const expe = this.$route.query.expedition;
      if (expe) {
        res.push({
          libelle: `Expédition #${expe}`,
          id: expe,
          key: "expedition",
        });
      }

      const cmd = this.$route.query.commande;
      if (cmd) {
        res.push({
          libelle: `Commande #${cmd}`,
          id: cmd,
          key: "commande",
        });
      }

      return res;
    },
  },
  watch: {
    /**
     * Quand la recherche change dans la queryString
     */
    "$route.query": {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const toNumber = (value) => parseInt(value, 10);

          if (toNumber(newValue.page) !== toNumber(oldValue.page)) {
            this.currentPage = toNumber(newValue.page) || 1;
          }

          if (newValue.sort !== oldValue.sort) {
            this.sort.sortAscended = newValue.sort.charAt(0) !== "-";
            this.sort.key = newValue.sort.replace("-", "");
          }

          this.fetchFactures();
        }
      },
    },
  },
  mounted() {
    const { page, sort } = this.$route.query;
    if (page) {
      this.currentPage = parseInt(page, 10);
    }
    if (sort) {
      this.sort.sortAscended = sort.charAt(0) !== "-";
      this.sort.key = sort.replace("-", "");
    }
    this.fetchFactures();
  },
  methods: {
    copyToClipboard,
    handleSort,
    /**
     * Récupère les factures.
     */
    fetchFactures() {
      this.isLoading = true;
      const filterQuery = this.activeFilters.map(({ slug, options }) => [`${slug}__in`, options.join(",")]);
      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        ...Object.fromEntries(filterQuery),
      };
      if (this.sort?.key) {
        params.ordering = `${this.sort.sortAscended ? "" : "-"}${this.sort.key}`;
      }
      this.activeFilters.forEach((f) => {
        params[f.key] = f.id;
      });

      Api().get("/facture/", { params })
        .then(({ data: { results, count, filtres } }) => {
          this.factures = results;
          this.totalRows = count;
          this.allFilters = filtres;
        })
        .catch((err) => {
          this.$toast.error({ title: err.data || err });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * Réinitialise les filtres et met la paginsation sur 1.
     */
    resetCrossFilters() {
      this.$router.push({ name: this.$route.name });
      this.currentPage = 1;
    },
    /**
     * Enlève le filtre et rafraîchit les résultats.
     * @param {Object} filter Filtre à supprimer.
     */
    uncheckCrossFilter(filter) {
      this.$router.push({
        name: this.$route.name,
        ...{ ...this.$route.query, [filter.key]: undefined },
      });
    },
  },
};
</script>

<style lang="scss"></style>
